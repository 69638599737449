import { formatPublishDate } from 'utils/conversions'

export const createPublicViewData = (story) => {
  const storyData = [
    {
      icon: '/multi-site-assets/confetti.svg',
      eyebrow: '#BREAKUPWITHDEBT',
      title: `${story.name.split(' ')[0]} and debt are over!`,
      subtext: "Let's review their breakup story",
      bgColor: 'bg-[#14419A]',
      textColor: 'text-neutral-0',
      showSvg: true,
    },
    story.sameDayJoins > 0 && {
      icon: '/multi-site-assets/map.svg',
      eyebrow: `${formatPublishDate(story.startDate)}`,
      title: `${story.sameDayJoins}`,
      subtext: 'others like them from across the country took the leap to put debt in the past',
      bgColor: 'bg-yellow-500',
      textColor: '!text-[#000F66]',
    },
    {
      icon: '/multi-site-assets/time.svg',
      eyebrow: `${formatPublishDate(story.dateOfFirstSettlement)}`,
      title: `${story.daysToFirstResolution} days later,`,
      subtext: 'they kicked their first debt to the curb!',
      bgColor: 'bg-green-600',
      textColor: 'text-neutral-0',
    },
    {
      icon: '/multi-site-assets/calendar_dollar.svg',
      eyebrow: 'THEY PAID OFF...',
      title: `${story.totalResolvedDebts} debts in ${story.totalMonths} months`,
      subtext: 'debt begged for another chance, but progress said no.',
      bgColor: 'bg-[#2C70E3]',
      textColor: 'text-neutral-0',
    },
    {
      icon: '/multi-site-assets/rocket.svg',
      eyebrow: `${formatPublishDate(story.endDate)}`,
      title: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(story.totalDebtAmount),
      subtext: `of enrolled debt was finally in ${story.name.split(' ')[0]}'s past!`,
      bgColor: 'bg-yellow-500',
      textColor: '!text-[#000F66]',
    },
  ]

  return storyData.filter(Boolean)
}

export const createGraduatedViewData = (story) => {
  const storyData = [
    {
      icon: 'multi-site-assets/confetti.svg',
      eyebrow: '#BREAKUPWITHDEBT',
      title: "Your future with no debt looks great",
      subtext: "Let's review your breakup story",
      bgColor: 'bg-[#14419A]',
      textColor: 'text-neutral-0',
      showSvg: true,
    },
    story.sameDayJoins > 0 && {
      icon: 'multi-site-assets/map.svg',
      eyebrow: `${formatPublishDate(story.startDate)}`,
      title: `${story.sameDayJoins}`,
      subtext: 'others like you from across the country took the leap to put debt in the past',
      bgColor: 'bg-yellow-500',
      textColor: '!text-[#000F66]',
    },
    {
      icon: 'multi-site-assets/time.svg',
      eyebrow: `${formatPublishDate(story.dateOfFirstSettlement)}`,
      title: `${story.daysToFirstResolution} days later,`,
      subtext: 'You kicked their first debt to the curb!',
      bgColor: 'bg-green-600',
      textColor: 'text-neutral-0',
    },
    {
      icon: 'multi-site-assets/calendar_dollar.svg',
      eyebrow: 'YOU PAID OFF...',
      title: `${story.totalResolvedDebts} debts in ${story.totalMonths} months`,
      subtext: 'debt begged for another chance, but progress said no.',
      bgColor: 'bg-[#2C70E3]',
      textColor: 'text-neutral-0',
    },
    {
      icon: 'multi-site-assets/rocket.svg',
      eyebrow: `${formatPublishDate(story.endDate)}`,
      title: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(story.totalDebtAmount),
      subtext: `of enrolled debt was finally in your past!`,
      bgColor: 'bg-yellow-500',
      textColor: '!text-[#000F66]',
    },
    {
      icon: 'multi-site-assets/finger-pointing.svg',
      eyebrow: '',
      title: 'Are you sure?',
      subtext:
        'Your story inspires others to #BreakUpWithDebt. Unpublishing removes it from this page, but you can still access it via the email we sent you.',
      bgColor: 'bg-white',
      textColor: 'text-content-primary',
      subtextColor: 'text-content-secondary',
      ignore: true,
    },
    {
      icon: 'multi-site-assets/lock.svg',
      title: 'Consider your breakup private!',
      subtext: 'Your story is unpublished.',
      bgColor: 'bg-white',
      textColor: 'text-content-primary',
      subtextColor: 'text-content-secondary',
      ignore: true,
    },
  ]

  return storyData.filter(Boolean)
}
