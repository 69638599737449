import { createContext, useState, useContext } from 'react'
import { WallOfFameStoryModalCarousel } from 'components/Carousels/WallOfFameStoryModalCarousel'

const GraduateContext = createContext()

const GraduateProvider = ({ children, initialState = null, initialHighlights = {} }) => {
  const [graduate, setGraduate] = useState(initialState)
  const [isModalOpen, setIsModalOpen] = useState(initialState)

  return (
    <GraduateContext.Provider value={{ graduate, setGraduate, ...initialHighlights }}>
      {graduate && (
        <WallOfFameStoryModalCarousel
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isGraduatedView
          story={graduate}
        />
      )}
      {children}
    </GraduateContext.Provider>
  )
}

const useGraduate = () => {
  const context = useContext(GraduateContext)

  if (!context) {
    //if not context preset return new empty one
    return createContext()
  }

  return context
}

export { GraduateProvider, useGraduate }
