import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { Image } from 'components/Image'

export const CardStory = ({ onClose, footerContent, ...story }) => {
  return (
    <div className={`md:w-[672px] w-[327px] relative ${story.bgColor} rounded-md`}>
      <div className={`relative overflow-hidden md:h-[522px] h-[384px] flex flex-col gap-8 md:px-8 px-4 md:py-16 py-8`}>
        {story?.showSvg &&
          <div className='absolute -bottom-10 left-1/2 -translate-x-1/2'>
            <svg
              width="673"
              height="258"
              viewBox="0 0 673 258"
            >
              <path d="M390.162 249.901C389.496 249.751 388.83 249.634 388.142 249.472L479.878 18.9821L433.449 263.998C420.151 258.304 406.46 253.726 392.35 250.363L390.162 249.901Z" fill="#123C93" />
              <path d="M443.973 268.737C441.521 267.599 439.071 266.462 436.586 265.364L571.065 56.8197L478.444 288.524C468.012 281.486 457.261 275.19 446.095 269.746L443.973 268.737Z" fill="#123C93" />
              <path d="M493.804 299.588C489.677 296.371 485.41 293.291 481.072 290.318L653.309 112.099L518.26 320.622C511.065 313.706 503.577 307.211 495.842 301.108L493.804 299.588Z" fill="#123C93" />
              <path d="M537.914 341.206C532.356 334.856 526.598 328.786 520.642 322.95L723.386 182.646L552.379 358.955C548.309 353.587 544.127 348.402 539.807 343.395L537.914 341.206Z" fill="#123C93" />
              <path d="M383.132 -0.00012207L384.48 248.673C369.14 245.578 353.43 243.884 337.391 243.754L383.132 -0.00012207Z" fill="#123C93" />
              <path d="M280.923 250.598C281.587 250.439 282.252 250.314 282.938 250.142L188.274 20.8408L237.819 265.245C251.044 259.382 264.675 254.63 278.741 251.087L280.923 250.598Z" fill="#123C93" />
              <path d="M227.357 270.118C229.794 268.949 232.229 267.78 234.7 266.651L97.576 59.8378L193.14 290.342C203.482 283.173 214.152 276.74 225.247 271.153L227.357 270.118Z" fill="#123C93" />
              <path d="M177.922 301.601C182.008 298.332 186.236 295.198 190.535 292.17L16.0435 116.161L153.736 322.945C160.842 315.938 168.247 309.349 175.904 303.147L177.922 301.601Z" fill="#123C93" />
              <path d="M134.346 343.779C139.822 337.359 145.503 331.215 151.384 325.303L-53.1298 187.595L120.108 361.71C124.109 356.291 128.225 351.053 132.481 345.992L134.346 343.779Z" fill="#123C93" />
              <path d="M284.77 0.626709L286.589 249.297C301.888 246.007 317.576 244.113 333.612 243.778L284.77 0.626709Z" fill="#123C93" />
            </svg>
          </div>
        }
        <div onClick={onClose} className="absolute cursor-pointer md:top-6 top:12 right-4">
          <Image
            className="invert"
            src="/multi-site-assets/close.svg"
            alt="Close button"
            width={18}
            height={18}
            priority={false}
            aria-hidden={true}
            data-testid="card-story-x-icon"
          />
        </div>
        <Image
          alt="Illustration of a confetti"
          height={104}
          width={104}
          className="md:w-[104px] md:h-[104px] w-[96px] h-[96px] self-center"
          priority={false}
          src={story.icon}
        />

        <div className={`flex flex-col gap-4 z-10 ${story.textColor}`}>
          {story?.eyebrow && (
            <Eyebrow
              className={`p-0 md:text-[24px] text-[16px] ${story.textColor}`}
              variant="headingMd"
              fontWeight="medium"
              content={story?.eyebrow}
            />
          )}
          <Typography
            className={`text-center md:text-[64px] text-[32px] leading-[120%] ${story.textColor} text-wrap`}
            content={story?.title}
            fontWeight="bold"
          />
          <Typography
            className={`text-center md:text-[24px] text-[16px] text-wrap ${
              story.subtextColor ? story.subtextColor : story.textColor
            }`}
            fontWeight="regular"
            content={story?.subtext}
          />
        </div>
      </div>
      {footerContent && (
        <div className="md:min-h-[96px] min-h-[64px] bg-white flex justify-center items-center rounded-b-md">
          {footerContent}
        </div>
      )}
    </div>
  )
}
