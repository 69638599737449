import {
  GET_STARTED_ROUTE,
  DIRECT_MAILER_ROUTE,
  RESOLVE_DEBT_APPLY_ROUTE,
  ADVERTORIAL_ROUTE,
  LANDER_ROUTE,
  WALL_OF_FAME_ROUTE,
} from 'constants/navigation'

const isGetStartedRoute = (href) => {
  return (
    href !== undefined &&
    href?.trim()?.replace('/', '').startsWith(GET_STARTED_ROUTE.split('?')[0].replace('/', ''))
  )
}

const isResolveApplyRoute = (href) => {
  return href !== undefined && href?.startsWith(RESOLVE_DEBT_APPLY_ROUTE)
}

const isDirectMailerRoute = (href) => {
  return (
    href !== undefined &&
    href?.trim()?.replace('/', '').startsWith(DIRECT_MAILER_ROUTE.split('?')[0].replace('/', ''))
  )
}

const isStandaloneRoute = (route) => {
  if (!route) return false
  return (
    route.includes(ADVERTORIAL_ROUTE) ||
    route.includes(LANDER_ROUTE) ||
    route.includes(WALL_OF_FAME_ROUTE)
  )
}

export { isResolveApplyRoute, isGetStartedRoute, isDirectMailerRoute, isStandaloneRoute }
