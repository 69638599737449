import { useState, useRef, useEffect } from 'react'
import { CardStory } from 'components/Cards/CardStory'
import { DotIndicators } from 'components/DotIndicators'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Button, IconButton, CircularProgress } from '@achieve/ascend'
import { useRouter } from 'next/router'
import { createGraduatedViewData, createPublicViewData } from './data'
import logger from '@achieve/cx-logger'
import { WALL_OF_FAME_ROUTE } from 'constants/navigation'

export const WallOfFameStoryModalCarousel = ({
  story,
  isOpen,
  onClose,
  isGraduatedView = false,
}) => {
  const storyData = isGraduatedView ? createGraduatedViewData(story) : createPublicViewData(story)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const touchStartX = useRef(0)
  const touchEndX = useRef(0)
  const storiesLength = storyData.filter((story) => !story.ignore).length
  const router = useRouter()

  // Scroll Lock
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  if (!isOpen) return null

  // Carousel Navigation
  const goToNext = () => {
    if (currentIndex < storiesLength - 1) {
      setCurrentIndex((prev) => prev + 1)
    }
  }

  const goToPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1)
    }
  }

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].clientX
    const deltaX = touchStartX.current - touchEndX.current
    const swipeThreshold = 50

    if (Math.abs(deltaX) > swipeThreshold) {
      if (deltaX > 0 && currentIndex < storiesLength - 1) {
        goToNext()
      } else if (deltaX < 0 && currentIndex > 0) {
        goToPrev()
      }
    }
  }
  // End Carousel Navigation

  // Button Handlers
  const handleUnpublishClick = async () => {
    setLoading(true)
    try {
      const res = await fetch(`/api/wall-of-fame/unpublish-graduate?auth=${router.query.auth}`, {
        method: 'POST',
      })

      if (!res.ok) {
        throw new Error('Failed to unpublish')
      }

      setCurrentIndex(storyData.length - 1)
      window.location.href = WALL_OF_FAME_ROUTE;
    } catch (err) {
      logger.error('Failed to unpublish in WallOfFameStoryModalCarousel', err)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
    setCurrentIndex(0)
  }
  // End Button Handlers

  const handleViewOtherStories = () => {
    handleClose()
    const section = document.getElementById('targetSection')
    const scrollableDiv = document.getElementById('scrollableDiv')

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    if (scrollableDiv) {
      scrollableDiv.scrollTo({ left: 0, behavior: 'smooth' })
    }
  }

  const getFooterContent = () => {
    return (
      <div className="flex flex-col">
        {/* Navigation Indicators */}
        {((isGraduatedView && currentIndex < storyData.length - 2) || !isGraduatedView) && (
          <div className="flex self-center p-4 gap-3">
            <IconButton
              className={`transition ${
                currentIndex === 0 ? 'cursor-not-allowed text-gray-400' : 'hover:bg-neutral-75'
              }`}
              onClick={goToPrev}
              aria-label="Previous story"
              disabled={currentIndex === 0}
            >
              <ChevronLeft size={24} />
            </IconButton>
            <DotIndicators
              size={storiesLength}
              activeIndex={currentIndex}
              classNameIndicators="flex gap-4"
              classNameIndicatorDots="h-[6px] w-[6px] bg-neutral-150 m-0 opacity-100 transition-all"
              classNameActiveDot="!bg-blue-600"
            />
            <IconButton
              onClick={goToNext}
              className={`transition ${
                currentIndex === storiesLength - 1
                  ? 'cursor-not-allowed text-gray-400'
                  : 'hover:bg-neutral-75'
              }`}
              aria-label="Next story"
              disabled={currentIndex === storiesLength - 1}
            >
              <ChevronRight size={24} />
            </IconButton>
          </div>
        )}

        {isGraduatedView && story?.isPublic && currentIndex === storyData.length - 3 && (
          <div className="flex flex-col justify-center gap-4 pb-6 w-[279px]">
            <Button className="bg-blue-600 text-white px-4 py-2" onClick={handleViewOtherStories}>
              View other stories
            </Button>
            <Button
              variant="text"
              className="text-content-accent px-4 py-2"
              onClick={() => setCurrentIndex((prev) => prev + 1)}
            >
              Unpublish this story
            </Button>
          </div>
        )}

        {/* Graduated Unpublish Actions Card */}
        {isGraduatedView && story?.isPublic && currentIndex === storyData.length - 2 && (
          <div className="flex flex-col justify-center gap-4 pb-6 w-[279px]">
            <Button
              onClick={handleUnpublishClick}
              disabled={loading}
              className={`${
                loading ? 'bg-neutral-100' : 'bg-blue-600'
              } text-white px-4 py-2 flex items-center justify-center gap-2`}
            >
              {loading ? <CircularProgress size={20} /> : 'Yes, unpublish my story'}
            </Button>
            <Button
              variant="text"
              className="text-content-accent px-4 py-2"
              disabled={loading}
              onClick={handleViewOtherStories}
            >
              Keep my story published
            </Button>
          </div>
        )}

        {/* Graduated Last Card */}
        {isGraduatedView && currentIndex === storyData.length - 1 && (
          <div className="flex flex-col justify-center gap-4 pb-6 w-[279px]">
            <Button className="bg-blue-600 text-white px-4 py-2" onClick={handleViewOtherStories}>
              View other stories
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="absolute inset-0 bg-[#27313EBF] bg-opacity-75 backdrop-blur-sm"
        onClick={handleClose}
      />

      <div
        className="relative flex flex-col items-center"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <CardStory
          {...storyData[currentIndex]}
          onClose={handleClose}
          footerContent={getFooterContent()}
        />
      </div>
    </div>
  )
}
