import classNames from 'classnames'
import { Typography } from 'components/Contentful'

function Eyebrow({ className, black = false, variant = 'bodySm', ...restProps }) {
  return (
    <Typography
      variant={variant}
      fontWeight="bold"
      className={classNames(
        'group',
        'leading-[16.6px]',
        'pb-4',
        black ? 'text-[#5c708a]' : 'text-[#30f]',
        'text-center',
        'tracking-[0.25em]',
        'uppercase',
        className
      )}
      {...restProps}
    />
  )
}

export { Eyebrow }
